import React from 'react';
import './index.scss';

const VerticalAdBanner = () => {
    return (
        <div className="ad-banner-container p-hide">
            <div className="badge">Ads</div>
            <div className="ad-banner">
                {/* Adsterra Banner Ad Code */}

            </div>
        </div>
    );
};

export default VerticalAdBanner;
