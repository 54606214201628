import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';


export default function Button(props) {


  return (
    <div className="button-container rounded-pill p-1">
      <div className="m-0 p-0 bg-effect-button  rounded-pill "></div>
      <button className="btn p-2 rounded-pill button-theme w-auto m-0" type="button" onClick={props.onClick}>
        <b>{props.text}</b><i className={'fa ' + props.icon} ></i>
      </button>

    </div>
  );

}