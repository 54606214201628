import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SocialMediaShare = () => {
    const [showModal, setShowModal] = useState(false);

    const shareLinks = {
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=https://www.showflex.co.za',
        whatsapp: 'https://wa.me/?text=https://www.showflex.co.za',
        twitter: 'https://twitter.com/intent/tweet?url=https://www.showflex.co.za',
        tiktok: 'https://www.tiktok.com/share?url=https://www.showflex.co.za'
    };

    const handleModalToggle = () => setShowModal(!showModal);

    return (
        <div>
            {/* Desktop view */}
            <div className="d-none d-lg-block" style={styles.container}>
                <a href={shareLinks.facebook} className="hover" target="_blank" rel="noopener noreferrer" style={styles.iconContainer}>
                    <i className="fab fa-facebook-f" style={styles.icon}></i>
                </a>
                <a href={shareLinks.whatsapp} className="hover" target="_blank" rel="noopener noreferrer" style={styles.iconContainer}>
                    <i className="fab fa-whatsapp" style={styles.icon}></i>
                </a>
                <a href={shareLinks.twitter} className="hover" target="_blank" rel="noopener noreferrer" style={styles.iconContainer}>
                    <i className="fab fa-twitter" style={styles.icon}></i>
                </a>
                <a href={shareLinks.tiktok} className="hover" target="_blank" rel="noopener noreferrer" style={styles.iconContainer}>
                    <i className="fab fa-tiktok" style={styles.icon}></i>
                </a>
            </div>

            {/* Mobile view */}
            <div className="d-lg-none">
                <button onClick={handleModalToggle} style={styles.mobileButton}>
                    <i className="fas fa-share-alt" style={styles.icon}></i>
                </button>
            </div>

            {showModal && (
                <div style={styles.modalOverlay} onClick={handleModalToggle}>
                    <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
                        <div style={styles.modalHeader}>
                            <span style={styles.modalTitle}>Share on Social Media</span>
                            <button onClick={handleModalToggle} style={styles.closeButton}>&times;</button>
                        </div>
                        <div style={styles.modalBody}>
                            <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer" style={styles.iconContainer}>
                                <i className="fab fa-facebook-f" style={styles.icon}></i>
                            </a>
                            <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" style={styles.iconContainer}>
                                <i className="fab fa-whatsapp" style={styles.icon}></i>
                            </a>
                            <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer" style={styles.iconContainer}>
                                <i className="fab fa-twitter" style={styles.icon}></i>
                            </a>
                            <a href={shareLinks.tiktok} target="_blank" rel="noopener noreferrer" style={styles.iconContainer}>
                                <i className="fab fa-tiktok" style={styles.icon}></i>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        position: 'fixed',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        background: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.21))',
        padding: '10px',
        zIndex: 1000,
        borderRadius: '0px 15px 15px 0px'
    },
    iconContainer: {
        display: 'block',
        marginBottom: '10px',
        backgroundColor: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 4px 8px rgba(217, 217, 217, 1)',
        textDecoration: 'none',
    },
    icon: {
        color: '#550C0C',
        fontSize: '20px',
    },
    mobileButton: {
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        backgroundColor: 'white',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 4px 8px rgba(217, 217, 217, 1)',
        zIndex: 1000,
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(217, 217, 217, 1)',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    modalTitle: {
        fontSize: '18px',
    },
    closeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
};

export default SocialMediaShare;
