import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';
import MusicList from './MusicList'; // Adjust the import path based on your file structure
import background from '../../assets/img/music.jpg'

import Button from '../../components/Button';

const CardWithSliders = () => {
    const [autoplay, setAutoplay] = useState(true);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoplay,
        autoplaySpeed: 3000,
    };

    const toggleAutoplay = () => {
        setAutoplay(!autoplay);
    };

    return (
        <div style={styles.card} className="card-sliders">
            <div style={styles.header}>
                <h3 style={styles.headerText}>Top Trending Songs</h3>
            </div>
            <div style={styles.body} className="px-4">
                <div style={styles.iconContainer} onClick={toggleAutoplay}>
                    <i className={`fa ${autoplay ? 'fa-pause' : 'fa-play'}`} style={styles.icon}></i>
                </div>
                <div className="music-list-container">
                    <MusicList />
                </div>
            </div>
        </div>
    );
};

const styles = {
    card: {
        position: 'absolute',
        bottom: '15%',
        left: '10%',
        width: '80%',
        backgroundColor: 'transparent',
        border: '1px solid #ccc',
        borderRadius: '10px',
        overflow: 'hidden',
        '@media (max-width: 768px)': {
            width: '90%',
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    header: {
        backgroundColor: '#fff',
        padding: '10px',
        textAlign: 'center',
        borderRadius: '10px 10px 0px 0px',

    },
    headerText: {
        color: '#550C0C',
        margin: 0,
    },
    body: {
        padding: '10px',
        position: 'relative',
    },
    iconContainer: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        padding: '10px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 10,
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        color: '#550C0C',
        fontSize: '15px',
    },
};

export default function Videos(props) {
    return (
        <div id="home">
            <div className="container-slider container-fluid m-0 p-0" id="container-slider">
                <div className="container-fluid m-0 p-0 img-home">
                    <img className="position-relative" src={background} alt={'check'} />
                </div>
                <div className="m-0 p-0 slider-body">
                    <div className="thing m-0 py-1 px-0 bg-theme">
                        <CardWithSliders />
                    </div>
                </div>
            </div>
            <div className='button-slider animate__animated animate__fadeInUp animate__slow'>
                <Button text="More Music" icon="fa-arrow-plus" />
            </div>
        </div>
    );
}
