import React from 'react';
import { useState } from 'react';
import LinkButton from '../../components/LinkButton';
import MenuBar from '../MenuBar';
import WhatsappIcon from '../../components/WhatsappIcon';

import img from '../../assets/img/Qala_logo.png';
import './index.scss';
import { Outlet, Link } from "react-router-dom";
import SocialMediaShare from '../../components/SocialMediaShare';



export default function Layout() {


    const Links = [
        { id: 1, text: 'Home', link: "home" },
        { id: 10, text: 'Music', link: "home" },
        { id: 2, text: 'About us', link: "About" },
        { id: 3, text: 'Reality TV', link: "Reality" },
        { id: 4, text: 'qNCA', link: "qNCA" },
        { id: 5, text: 'Drama TV', link: "Drama" },
        { id: 6, text: 'Travel FM', link: "Travel" },
        { id: 7, text: 'Biz TV', link: "Biz" },
        { id: 8, text: 'Gospel TV', link: "Gospel" },
        { id: 9, text: 'Cartoons TV', link: "Cartoons" }
    ];

    let icon = () => {
        return (
            <>
                <i className='fa fa-angle-down'></i>
            </>

        )
    }
    let icon1 = () => {
        return (
            <>
                <i className='fa fa-angle-up'></i>
            </>

        )
    }

    const [activeTab, setActiveTab] = useState('Videos');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    return (
        <>
            <div className="nav container-fluid animate__animated m-0 p-1 w-100 out-viewport text-dark" id="header-bar">


                <div className="title bg-theme px-4 pt-1 title-name" >
                    <h3 className='rounded text-white'>Showflex.com</h3>
                </div>
                <div className='logo-top p-hide ml-4 mt-1' ><img src={img} alt='logo' /> </div>



                <div className='container-fluid mx-0 d-flex d-hide flex-row'>
                    <div className='logo-header animate__animated animate__zoomIn animate__slow'>
                        <img src={img} alt='logo' />
                    </div>
                    <div className='ml-2 rounded d-flex flex-row flex-nowrap align-items-start overflow-x-hidden overflow-y-hidden menu-small'>

                        <Link to='/home'
                            className={`my-0 text-small border text-theme px-3 py-1 rounded shadow mx-1 text-underline ${activeTab === 'Videos' ? 'active' : ''}`}
                            onClick={() => handleTabClick('Videos')}
                        >
                            Videos
                        </Link>
                        <Link to='/music'
                            className={`my-0 text-small border text-theme px-3 py-1 rounded shadow mx-1 text-underline ${activeTab === 'Music' ? 'active' : ''}`}
                            onClick={() => handleTabClick('Music')}
                        >
                            Music
                        </Link>

                        <Link to='/news' className={` my-0 text-small border text-theme px-3 py-1 rounded shadow mx-1 text-underline dropdown-toggle ${activeTab === 'News' ? 'active' : ''}`}>
                            <span
                                className=''
                                onClick={() => handleTabClick('News')}
                                id='dropdownMenuButton'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                            >
                                <b>News</b>
                            </span>
                            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                <a
                                    className={`dropdown-item ${activeTab === 'Youth News' ? 'active' : ''}`}
                                    href='#youth-news'
                                    onClick={() => handleTabClick('Youth News')}
                                >
                                    Youth News
                                </a>
                                <a
                                    className={`dropdown-item ${activeTab === 'Zwakala News' ? 'active' : ''}`}
                                    href='#zwakala-news'
                                    onClick={() => handleTabClick('Zwakala News')}
                                >
                                    Zwakala News
                                </a>
                                <a
                                    className={`dropdown-item ${activeTab === 'Kasi Business Magazine' ? 'active' : ''}`}
                                    href='#kasi-business-magazine'
                                    onClick={() => handleTabClick('Kasi Business Magazine')}
                                >
                                    Kasi Business Magazine
                                </a>
                            </div>
                        </Link>


                    </div>
                </div>

                <div className='w-auto mx-0 mt-2 p-0 nav-bar-items d-flex flex-direction-row'>
                    {Links.map((Links) => <LinkButton key={Links.id} text={Links.text} href={Links.link} />)}
                </div>
                <MenuBar />
            </div>

            <WhatsappIcon phone="+27728108533" />

            <SocialMediaShare />

            <Outlet />
        </>

    )
}