import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';



import Slider from '../Slider';

import img from '../../assets/img/43b1fd37c6c84f658d01c66a26e65a38.jpg';

import img1 from '../../assets/img/s-l1200.jpg';

import logo from '../../assets/img/Qala_logo.png'

import logo1 from '../../assets/img/Qala_logo.png'

var indexSlide = 0;

//This is to add remove classes from animated elements so that they can animate again when the state changes

function getSlide(list) {
  return (
    <>
      <Slider title={list.title} caption={list.caption} imgURL={list.imgURL} alt={list.alt} logo={list.logo} />
    </>
  )
}

let mission = "ANC veterans in Mpumalanga say former president Jacob Zuma's decision to form the uMkhonto weSizwe (MK) Party proves that he's always had a double agenda...";
let version = "ANC veterans in Mpumalanga say former president Jacob Zuma's decision to form the uMkhonto weSizwe (MK) Party proves that he's always had a double agenda...";

function isElementVisible(el) {

  var rect = el.getBoundingClientRect();
  var vWidth = window.innerWidth || document.documentElement.clientWidth;
  var vHeight = window.innerHeight || document.documentElement.clientHeight;


  // Return false if it's not in the viewport || 
  //console.log(rect.top);
  if (rect.top < 0) {
    return false;
  }

  else {
    return true;
  }


}

export default function Header() {


  const Sliders = { id: 2, title: "Topic Here", caption: version, imgURL: img1, alt: "backgroud img", logo: logo }


  useEffect(() => {
    var el1 = document.getElementById("header-bar");


    // const setint = setInterval(() => {
    //   let el = document.getElementsByClassName("container-slider")[0];

    //   if (el.style.display != "block") {
    //     el = document.getElementsByClassName("container-slider")[1];
    //   }

    //   let icons = [document.getElementById("scroll1"), document.getElementById("scroll2")]

    //   if (!isElementVisible(el)) {


    //     icons[0].style.display = "none";
    //     icons[1].style.display = "block";
    //   }
    //   else {

    //     icons[1].style.display = "none";
    //     icons[0].style.display = "block";

    //   }

    //   if (indexSlide < Sliders.length - 1) {
    //     indexSlide++;
    //     document.getElementsByClassName("container-slider")[0].style.display = "none";
    //     document.getElementsByClassName("container-slider")[indexSlide].style.display = "block";


    //   }
    //   else {
    //     indexSlide = 0;
    //     document.getElementsByClassName("container-slider")[1].style.display = "none";
    //     document.getElementsByClassName("container-slider")[indexSlide].style.display = "block";


    //   }



    // }, 12000);




  }

    , [])





  return (

    <>
      <Slider title={Sliders.title} imgURL={Sliders.imgURL} text={Sliders.text} logo={Sliders.logo} caption={Sliders.caption} />
    </>



  )
}