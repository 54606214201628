import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss'; // Make sure your styling is imported correctly
import background from '../../assets/img/Background.png'; // Adjust path as per your file structure
import Button from '../../components/Button'; // Adjust path as per your file structure

const CardWithSliders = () => {
    const [autoplay, setAutoplay] = useState(true);
    const [playingVideoId, setPlayingVideoId] = useState(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoplay,
        autoplaySpeed: 3000,
    };

    const videos = [
        {
            id: 1,
            videoUrl: 'https://medsmadiagane.co.za/news.mp4',
            thumbnail: 'https://www.aljazeera.com/wp-content/uploads/2022/08/000_32FT7KX.jpg?resize=770%2C513&quality=80',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, est in tincidunt tincidunt.',
            downloadLink: 'https://eltontry.com/d0p720muxu?key=44453286f020b80c281e13f79284891c'
        },
        {
            id: 2,
            videoUrl: 'https://medsmadiagane.co.za/news.mp4',
            thumbnail: 'https://www.aljazeera.com/wp-content/uploads/2021/07/2021-07-12T150210Z_1449390549_RC2YIO9RVAYZ_RTRMADP_3_SAFRICA-ZUMA-PROTESTS.jpg?resize=770%2C513&quality=80',
            description: 'Video 2 Description',
            downloadLink: 'https://eltontry.com/d0p720muxu?key=44453286f020b80c281e13f79284891c'
        },
        {
            id: 3,
            videoUrl: 'https://medsmadiagane.co.za/news.mp4',
            thumbnail: 'https://www.sanews.gov.za/sites/default/files/2012/styles/gallery_large/public/images/topimage/202406/joe-phaahla-briefing-2.png?itok=vuZZrJsP',
            description: 'Video 3 Description',
            downloadLink: 'https://eltontry.com/d0p720muxu?key=44453286f020b80c281e13f79284891c'
        },
    ];

    const toggleAutoplay = () => {
        setAutoplay(!autoplay);
    };

    const handlePlayButtonClick = (id) => {
        setPlayingVideoId(id);
    };

    return (
        <div style={styles.card} className="card-sliders">
            <div style={styles.header}>
                <h3 style={styles.headerText}>Top Trending Videos</h3>
            </div>
            <div style={styles.body} className="px-4">
                <div style={styles.iconContainer} onClick={toggleAutoplay}>
                    <i className={`fa ${autoplay ? 'fa-pause' : 'fa-play'}`} style={styles.icon}></i>
                </div>
                <Slider {...settings}>
                    {videos.map((video) => (
                        <div key={video.id} style={styles.slideContainer}>
                            <div style={styles.videoWrapper}>
                                {playingVideoId === video.id ? (
                                    <video
                                        controls
                                        controlsList="nodownload"
                                        style={styles.video}
                                        autoPlay
                                    >
                                        <source src={video.videoUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <div style={styles.thumbnailWrapper}>
                                        <img src={video.thumbnail} alt="Thumbnail" style={styles.thumbnail} />
                                        <div style={styles.playButton} onClick={() => handlePlayButtonClick(video.id)}>
                                            <i className="fa fa-play" style={styles.playIcon}></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div style={styles.descriptionWrapper}>
                                <p style={styles.description}>{video.description}</p>
                                <a href={video.downloadLink} download style={styles.downloadButton}>
                                    Download
                                </a>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

const styles = {
    card: {
        position: 'absolute',
        bottom: '15%',
        left: '10%',
        width: '80%',
        backgroundColor: 'transparent',
        border: '1px solid #ccc',
        borderRadius: '10px',
        overflow: 'hidden',
        '@media (max-width: 768px)': {
            width: '90%',
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    header: {
        backgroundColor: '#fff',
        padding: '10px',
        textAlign: 'center',
        borderRadius: '10px 10px 0 0', // Adjusted for consistent border radius
    },
    headerText: {
        color: '#550C0C',
        margin: 0,
    },
    body: {
        padding: '10px',
        position: 'relative',
    },
    slideContainer: {
        position: 'relative',
    },
    videoWrapper: {
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden',
        borderRadius: '10px',
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '10px',
    },
    thumbnailWrapper: {
        position: 'relative',
        width: '100%',
        cursor: 'pointer',
    },
    thumbnail: {
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Ensures the image covers the entire container
        borderRadius: '10px',
    },
    playButton: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: '20px',
        cursor: 'pointer',
    },
    playIcon: {
        color: '#fff',
        fontSize: '30px',
    },
    descriptionWrapper: {
        backgroundColor: '#550C0C',
        color: '#fff',
        padding: '10px',
        textAlign: 'center',
        borderRadius: '0 0 10px 10px', // Adjusted for consistent border radius
        marginTop: '10px',
    },
    description: {
        margin: '0',
    },
    downloadButton: {
        display: 'inline-block',
        padding: '10px 20px',
        backgroundColor: '#fff',
        color: '#550C0C',
        borderRadius: '5px',
        textDecoration: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    iconContainer: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        padding: '10px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 10,
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        color: '#550C0C',
        fontSize: '15px',
    },
};

export default function Videos(props) {
    return (
        <div id="home">
            <div className="container-slider container-fluid m-0 p-0" id="container-slider">
                <div className="container-fluid m-0 p-0 img-home">
                    <img className="position-relative" src={background} alt={'background'} />
                </div>
                <div className="m-0 p-0 slider-body">
                    <div className="thing m-0 py-1 px-0 bg-theme">
                        <CardWithSliders />
                    </div>
                </div>
            </div>
            <div className='button-slider animate__animated animate__fadeInUp animate__slow'>
                <Button text="More Videos" icon="fa-arrow-plus" />
            </div>
        </div>
    );
}
