import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img from '../../assets/img/engineer.jpg';
import img1 from '../../assets/img/training centre.jpg';
import img2 from '../../assets/img/supply.jpg';
import './index.scss';
import VerticalAdBanner from '../VerticalAdBanner';
import Ads300by250 from '../Ads/Ads300by250';
import Ads728by90 from '../Ads/Ads728by90';
import Ads468by60 from '../Ads/Ads468by60';
import ScrollIcon from '../../components/ScrollIcon';
import Button from '../../components/Button';
import background from '../../assets/img/s-l1200.jpg'


const CardWithSliders = () => {
    const [autoplay, setAutoplay] = useState(true);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoplay, // Control autoplay dynamically
        autoplaySpeed: 3000, // Set the speed of auto-sliding (3000 ms = 3 seconds)
    };

    const slides = [
        {
            id: 1, image: 'https://cdn.24.co.za/files/Cms/General/d/11850/47b1c538d8d24b41a9bdb38be1d71347.jpeg',
            caption: 'Injured paramedic fights for recovery, justice after alleged assault by Joburg ANC bigwig', link: 'https://www.example.com/slide1'
        },
        {
            id: 2, image: 'https://cdn.24.co.za/files/Cms/General/d/11910/570b865d884b4d54975b49199f280aee.jpg',
            caption: 'Gown and out: Cash-strapped Hawks fork out more than R50k for Lebeya bespoke graduation gearGown and out: Cash-strapped Hawks fork out more than R50k for Lebeya bespoke graduation gear', link: 'https://www.example.com/slide2'
        },
        {
            id: 3, image: 'https://cdn.24.co.za/files/Cms/General/d/11906/754e074680af411b9cea118057b5e361.jpg',
            caption: 'DEVELOPING | Elections 2024: Smaller parties want to consult Zondo over government of national unity DEVELOPING | Elections 2024: Smaller parties want to consult Zondo over government of national unity',
            link: 'https://www.example.com/slide3'
        },
    ];

    const toggleAutoplay = () => {
        setAutoplay(!autoplay);
    };

    return (
        <div style={styles.card} className="card-sliders">
            <div style={styles.header}>
                <h3 style={styles.headerText}>ZWAKALA Newspaper</h3>
            </div>
            <div style={styles.body} className="px-4 pb-4">
                <div style={styles.iconContainer} onClick={toggleAutoplay}>
                    <i className={`fa ${autoplay ? 'fa-pause' : 'fa-play'}`} style={styles.icon}></i>
                </div>
                <Slider {...settings}>
                    {slides.map((slide) => (
                        <a key={slide.id} href={slide.link} target="_blank" rel="noopener noreferrer" style={styles.slideLink}>
                            <div style={{ ...styles.slide, backgroundImage: `url(${slide.image})` }}>
                                <div style={styles.overlay}></div>
                                <div style={styles.caption}>{slide.caption}</div>
                            </div>
                        </a>
                    ))}
                </Slider>
            </div>
        </div>
    );
};


const BigCard = () => {
    const [autoplay, setAutoplay] = useState(true);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    const slides = [
        {
            id: 1, image: 'https://cdn.24.co.za/files/Cms/General/d/11910/32fc014c69224af3a99e280900edc9af.jpeg',
            caption: 'Proteas out to avoid being triple-dutched in New York as pitch comes under scrutiny            ', link: 'https://www.example.com/slide1'
        },
        {
            id: 2, image: 'https://cdn.24.co.za/files/Cms/General/d/11910/354b9f94707f491eb32803396200976c.jpg',
            caption: 'HELLO WEEKEND | Classic and timeless: Siya Bunny reveals the secrets behind her opulent fashion flair', link: 'https://www.example.com/slide2'
        },
        {
            id: 3, image: "https://cdn.24.co.za/files/Cms/General/d/11910/a5afeca5ecc04b248ea9ec42b349c2f3.jpeg",
            caption: 'Ghost exports: Seven SARS officials to appear in court over R653m border post tax fraud', link: 'https://www.example.com/slide3'
        },
    ];

    const toggleAutoplay = () => {
        setAutoplay(!autoplay);
    };

    return (
        <div style={styles.card} className="big-slider p-hide">
            <div style={styles.header}>
                <h3 style={styles.headerText}>Kasi Business Megazine</h3>
            </div>
            <div style={styles.body} className="px-4 pb-4">

                <Slider {...settings}>
                    {slides.map((slide) => (
                        <a key={slide.id} href={slide.link} className="px-3" target="_blank" rel="noopener noreferrer" style={styles.slideLink}>
                            <div style={{ ...styles.slide, backgroundImage: `url(${slide.image})` }}>
                                <div style={styles.overlay}></div>
                                <div style={styles.caption}>{slide.caption}</div>
                            </div>
                        </a>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

const styles = {

    header: {
        backgroundColor: '#fff',
        padding: '10px',
        textAlign: 'center',
        borderRadius: '10px 10px 0px 0px',
    },
    headerText: {
        color: '#550C0C',
        margin: 0,
    },
    body: {
        padding: '10px',
        position: 'relative',
    },
    slideContainer: {
        position: 'relative',
    },
    slideLink: {
        display: 'block',
        textDecoration: 'none',
    },
    slide: {
        height: '200px',
        borderRadius: '10px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.75))',
        borderRadius: '10px',
    },
    caption: {
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        color: '#fff',

    },
    iconContainer: {
        position: 'absolute',
        top: '10px',
        right: '10px',

        backgroundColor: '#fff',
        borderRadius: '50%',
        padding: '10px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 10,
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        color: '#550C0C',
        fontSize: '15px',
    },
};

export default function Sliders(props) {


    let scrool = () => {
        let url = window.location.href;
        url = url.split("#");
        url = url[0] + "#Contact";
        window.location.href = url;

    }

    return (
        <div id="home">
            <div className="container-slider container-fluid m-0 p-0" id="container-slider">
                <div className="container-fluid m-0 p-0 img-home">
                    <img className="position-relative" src={background} alt={'back'} />
                </div>
                <div className="m-0 p-0 slider-body">
                    <div className="thing m-0 pt-1 pb-4 px-0 bg-theme">
                        <CardWithSliders />
                    </div>
                </div>
                <BigCard />
            </div>
            <div className='first-ad'>
                <Ads468by60 />
            </div>
            <div className='second-ad p-hide'>
                <Ads300by250 />
            </div>
            <div className='third-ad p-hide'>
                <VerticalAdBanner />
            </div>

            <div className='button-slider animate__animated animate__fadeInUp animate__slow'>
                <Button text="More News" icon="fa-arrow-plus" onClick={scrool} />
            </div>
        </div>
    );

}
