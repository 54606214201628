import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Our_clients from '../Our_clients'

import About from '../About/About';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';






export default function () {


    return (
        <>
            <div className="m-0 p-0 body-container">


            </div>
        </>
    )
}