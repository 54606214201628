import React, { useState } from 'react';
import error from '../../assets/img/correct.jpg';
import './index.scss';
import Button from '../../components/Button';


const url = window.location.href;
function getError(url) {
    let res;
    res = url.split("?");
    if (res.length >= 2) {

        res = res[1].split("&");
        if (res.length >= 2) {
            res = res[1].split("=");
        }
        else {
            res = res[0].split("=");
        }
        res = res[1];


    }
    return res;
}

export default function MenuBar(props) {

    let scrool = () => {
        let url = window.location.href;
        url = url.split("/");
        url = url[0].split("#");
        url = url[0] + "/home";
        window.location.href = url;

    }

    return (
        <div className='m-auto p-0 w-90 hw-100'>
            <div className="position-relative top-20 error_img text-center ">
                <img className="m-auto" src={error} />
            </div>
            <div className='w-auto position-relative error_msg text-center m-0 p-3 border rounded text-success h3'>
                Email Successfully sent <br /><br />
                <div className="button px-0 py-3"> <Button text="Go back" icon="fa-arrow-left" onClick={scrool} /></div>
            </div>

        </div >
    )
}