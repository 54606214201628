import React from 'react';
import './index.scss';

const Ads300by250 = () => {
    return (
        <div className="ad-banner-container1">
            <div className="badge1">Ads</div>
            <div className="ad-banner1">
                {/* Adsterra Banner Ad Code */}

            </div>
        </div>
    );
};

export default Ads300by250;
