import React from 'react';
import './index.scss';

const Ads468by60 = () => {
    return (
        <div className="ad-banner-container3">
            <div className="badge3">Ads</div>
            <div className="ad-banner3">
                {/* Adsterra Banner Ad Code */}
            </div>
        </div>
    );
};

export default Ads468by60;
