import React from 'react';
import './index.scss'; // Ensure your styling is imported

const MusicList = () => {
    // Dummy data for songs (replace with your actual song data)
    const songs = [
        {
            id: 1,
            name: 'Song 1',
            audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
            downloadLink: 'https://example.com/download-song1',
        },
        {
            id: 2,
            name: 'Song 2',
            audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
            downloadLink: 'https://example.com/download-song2',
        },
        {
            id: 3,
            name: 'Song 3',
            audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3',
            downloadLink: 'https://example.com/download-song3',
        },
    ];

    const [currentSong, setCurrentSong] = React.useState(null);

    const playSong = (song) => {
        if (currentSong && currentSong.id === song.id) {
            setCurrentSong(null); // Toggle pause if same song is clicked again
        } else {
            setCurrentSong(song);
        }
    };

    return (
        <div className="music-list">
            {songs.map((song) => (
                <div key={song.id} className="song-card">
                    <div className="song-icons">
                        <i className="fa fa-share-alt"></i> {/* Replace with your share icon */}
                        <a href={song.downloadLink}>
                            <i className="fa fa-download"></i> {/* Replace with your download icon */}
                        </a>
                    </div>
                    <div className="song-details">
                        <div className="song-name">{song.name}</div>
                        {currentSong && currentSong.id === song.id ? (
                            <audio controls autoPlay>
                                <source src={song.audioUrl} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        ) : (
                            <button className="play-button" onClick={() => playSong(song)}>
                                Play
                            </button>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MusicList;
